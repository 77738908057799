import React from 'react';

import Layout from '../components/logic/layouts/Layout';
import Section from '../components/logic/layouts/Section';
import SEO from '../components/seo';

import StyledH2 from '../components/styled/elements/StyledH2';
import StyledH3 from '../components/styled/elements/StyledH3';
import StyledParagraph from '../components/styled/elements/StyledParagraph';
import StyledUl from '../components/styled/elements/StyledUl';
import StyledLi from '../components/styled/elements/StyledLi';

const CookiesPage = () => (
  <Layout>
    <SEO title="Om cookies" />
    <Section medium marginTop="none">
      <StyledH2 textAlign="center">Användning av cookies</StyledH2>
      <StyledParagraph textAlign="center">Enligt lagen om elektronisk kommunikation informerar Iconomic härmed dig att vår webbplats använder sig av cookies.</StyledParagraph>
      <StyledH3 fontWeight="bold">Vi använder cookies på vår webbplats</StyledH3>
      <StyledParagraph>En cookie är en liten textfil som vår webbplats sparar på din dator som på olika sätt underlättar din användning av vår webbplats. Det finns två typer av cookies:</StyledParagraph>
      <StyledUl>
        <StyledLi fontWeight="light">Permanent cookie, sparar en fil under en längre tid på din dator. Den används till exempel vid funktioner som talar om vad som är nytt sedan du senast besökte oss.</StyledLi>
        <StyledLi fontWeight="light">Temporär cookie, används endast under den tid du besöker oss och försvinner automatiskt när du stänger din webbläsare.</StyledLi>
      </StyledUl>
      <StyledParagraph>På vår webbplats förekommer även så kallade tredjepartscookies, vilket är cookies från externa parter som vi anlitar för att exempelvis mäta och utvärdera trafiken på vår webbplats.</StyledParagraph>
      <StyledH3 fontWeight="bold">Varför använder vi cookies?</StyledH3>
      <StyledParagraph>Cookies gör så att vår webbplats känner igen dig som besökare och automatiskt tar fram de uppgifter som du tidigare registrerat. Cookies används för att underlätta för dig som besökare, till exempel genom att spara inställningar du valt eller visa anpassad information. Cookies hjälper oss också att samla in statistik om våra besökare så att vi lättare kan utveckla tjänster som du vill ha.</StyledParagraph>
      <StyledH3 fontWeight="bold">Om du vill undvika cookies</StyledH3>
      <StyledParagraph>Vill du inte ta emot cookies kan du göra en inställning på datorn som gör att din webbläsare automatiskt tackar nej till cookies, eller välja att bli informerad varje gång vi begär att få lagra en. Du kan också radera cookies när som helst. På din webbläsares hjälpsidor kan du finna information om hur du går tillväga.</StyledParagraph>
      <StyledH3 fontWeight="bold">Samtycke till användningen av cookies</StyledH3>
      <StyledParagraph>Besökare på vår webbplats samtycker till att vi använder cookies genom att webbläsaren är inställd på att tillåta att cookies tas emot. Väljer du att inte acceptera cookies kan funktionaliteten på vissa delar av webbplatsen bli begränsad.</StyledParagraph>
      <StyledH3 fontWeight="bold">Övrigt</StyledH3>
      <StyledParagraph>Dessa bestämmelser kan från tid till annan komma att ändras för att vi ska följa de lagstadgade krav och praxis som finns för hantering av cookies.</StyledParagraph>
    </Section>
  </Layout>
);

export default CookiesPage;
